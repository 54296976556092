/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect } from 'react';
import { useMaterialUIController, setLayout } from 'context';

// next
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

// prop-types
import PropTypes from 'prop-types';

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });

function PageLayout({ children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useRouter();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [dispatch, pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor="default" // 'white', 'light', 'default'
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </MDBox>
  );
}

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
